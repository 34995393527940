import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import LoginButton from "../../components/LoginButton/LoginButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";

import classes from "./IphoneInstructionsPage.module.scss";

const IphoneInstructionsPage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  // if (redirectUrl) {
  //   return <Redirect to={redirectUrl}></Redirect>;
  // }

  return (
    <div className={`${classes.FullScreen}`}>
      <LoginButton></LoginButton>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            Teacher's Note
          </div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <div
            style={{
              ...questionsDefaultStyle,
              ...questionsTransitionStyles[state],
            }}
            className={classes.QuestionText}
          >
            Listen on iPhone
            <div className={classes.SubQuestionText}>Using Alexa </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  display: "block",
                  flexDirection: "column",
                  textAlign: "left",
                  backgroundColor: "white",
                  padding: 15,
                  paddingLeft: 25,
                  paddingRight: 25,
                  borderRadius: 15,
                  marginTop: 15,
                  maxWidth: "85%",
                }}
              >
                <div className={`${classes.InstructionText}`}>
                  <div>
                    1. Download, open, and sign in to the{" "}
                    <a
                      className={classes.Link}
                      href="https://apps.apple.com/us/app/amazon-alexa/id944011620"
                    >
                      Amazon Alexa App
                    </a>
                    . You will need to create an Amazon account if you do not
                    have one already.{" "}
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    2. The app will then walk you through getting Alexa set up
                    on your phone. When prompted, be sure to grant Alexa
                    permission to access your location while using the app. This
                    will allow us to make sure we are getting you the right note
                    on the right day based on your timezone.
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    3. To talk to Alexa, press the round blue button at the top
                    of the screen
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    4. Enable the Teacher's Note skill by pressing the blue
                    button and saying,{" "}
                    <span className={classes.SpokenWords}>
                      "Enable Teacher's Note."
                    </span>{" "}
                    This step only needs to be done{" "}
                    <span className={classes.SpokenWords}>once</span>, the first
                    time you use Teacher's Note.
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    5. To hear what your teacher has posted for the day, press
                    the blue button and say{"  "}
                    <span className={classes.SpokenWords}>
                      "Alexa, open Teacher's Note."
                    </span>{" "}
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    6. Alexa will then ask you about the name of your school,
                    your teacher, and your class. Once you have provided this
                    information, your Teacher's Note will be played for you!{" "}
                    <br></br>
                    <br></br>
                    {"  "}
                    <span className={classes.SpokenWords}>Tips{"  "}</span>{" "}
                    <br></br>
                    Your teacher will have provided you with the name, school
                    name, and class name they are using on Teacher's Note - make
                    sure you say them exactly as they are!
                    <br></br>
                    If your class name has a course number in it, Alexa needs
                    you to say the number in a way she can understand. For
                    example, if your class name is History 211, Alexa needs you
                    to pronounce that as either "
                    <span style={{ fontStyle: "italic" }}>
                      History two hundred eleven
                    </span>
                    " or "
                    <span style={{ fontStyle: "italic" }}>
                      History two one one
                    </span>
                    "<br></br>
                    <br></br>
                    For more detailed instructions, please see our{" "}
                    <a
                      className={classes.Link}
                      //href="https://www.amazon.com/Harbourview-Technologies-LLC-Holy-Minute/dp/B08B3YT73G"
                      href="https://google.com"
                    >
                      Alexa Skill store page
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          // </div>
        )}
      </Transition>
    </div>
  );
};

export default IphoneInstructionsPage;
