import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import LoginButton from "../../components/LoginButton/LoginButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";

import classes from "./ListenInAppInstructions.module.scss";

const ListenInAppInstructions = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  // if (redirectUrl) {
  //   return <Redirect to={redirectUrl}></Redirect>;
  // }

  return (
    <div className={`${classes.FullScreen}`}>
      <LoginButton></LoginButton>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            Teacher's Note
          </div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <div
            style={{
              ...questionsDefaultStyle,
              ...questionsTransitionStyles[state],
            }}
            className={classes.QuestionText}
          >
            Listen on iPhone or Android
            <div className={classes.SubQuestionText}>
              Using the Teacher's Note app{" "}
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  display: "block",
                  flexDirection: "column",
                  textAlign: "left",
                  backgroundColor: "white",
                  padding: 15,
                  paddingLeft: 25,
                  paddingRight: 25,
                  borderRadius: 15,
                  marginTop: 15,
                  maxWidth: "85%",
                }}
              >
                <div className={`${classes.InstructionText}`}>
                  <div>
                    1. Download{" "}
                    <span className={classes.SpokenWords}>Teacher's Note</span>{" "}
                    from the{" "}
                    <a
                      className={classes.Link}
                      href="https://apps.apple.com/us/app/id1526591649"
                    >
                      App Store
                    </a>{" "}
                    or{" "}
                    <a
                      className={classes.Link}
                      href="https://play.google.com/store/apps/details?id=com.teachersnote"
                    >
                      Google Play Store
                    </a>
                    .
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    2. When you open the app, select that you are a{" "}
                    <span className={classes.SpokenWords}>Student</span> in
                    order to navigate to the listening screen.
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    3. Press the{" "}
                    <span className={classes.SpokenWords}>Add Class</span>{" "}
                    button and search for your teacher by their name and school
                    to find their classes. Press the plus button next to a class
                    to add it to your listening screen. This only needs to be
                    done the first time you listen to a class.
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    4. Once you have added a class, it will appear on your
                    listening screen. To hear what your teacher has posted for
                    the day, press the play button on a class on your listening
                    screen. Classes with nothing posted will not have a play
                    button to press - instead, they will have a yellow message
                    indicating no post is available.
                    <br></br>
                    <br></br>
                    {"  "}
                    <span className={classes.SpokenWords}>Tips{"  "}</span>{" "}
                    <br></br>
                    <br></br>
                    {/* For an easier way to add a class, just press{" "}
                    <span className={classes.SpokenWords}>SCAN QR</span> on the
                    Add Class screen and point your camera at the QR code
                    provided to you by your teacher.
                    <br></br> */}
                    If you want to reload your classes to make sure you are
                    looking at the most recent notes, just pull down on your
                    class list.
                    <br></br>
                    <br></br>
                    Your teacher will have provided you with the name and school
                    name they are using on Teacher's Note - when you are
                    searching for a teacher, make sure you type them exactly as
                    they are!
                    <br></br>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
          </div>
          // </div>
        )}
      </Transition>
    </div>
  );
};

export default ListenInAppInstructions;
