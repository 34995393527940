import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";

import classes from "./SpreadTheWordPage.module.scss";

const SpreadTheWordPage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  // if (redirectUrl) {
  //   return <Redirect to={redirectUrl}></Redirect>;
  // }

  return (
    <div className={`${classes.FullScreen}`}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            Teacher's Note
          </div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <div
            style={{
              ...questionsDefaultStyle,
              ...questionsTransitionStyles[state],
            }}
            className={classes.QuestionText}
          >
            Spread the Word
            <div className={classes.SubQuestionText}>
              How to let your listeners know you are on Teacher's Note and help
              them find you there{" "}
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  display: "block",
                  flexDirection: "column",
                  textAlign: "left",
                  backgroundColor: "white",
                  padding: 15,
                  paddingLeft: 25,
                  paddingRight: 25,
                  borderRadius: 15,
                  marginTop: 15,
                }}
              >
                {/* <div className={`${classes.InstructionText}`}>
                  Check back soon for everything you need to communicate with
                  your students and their parents about Teacher's Note!
                </div> */}
                <div className={`${classes.InstructionText}`}>
                  <div>
                    When you want to tell people about listening to you on
                    Teacher's Note, your communications should include the
                    following:
                    <br></br>
                    <br></br>
                    1.{" "}
                    <span className={classes.SpokenWords}>
                      Your Teacher's Note names
                    </span>{" "}
                    - your listeners will need the correct name, school name,
                    class name, and password this to be able to ask for you on
                    Alexa and Google assistant or to look you up in the app.
                    <br></br>
                    <br></br>
                    2.{" "}
                    <span className={classes.SpokenWords}>
                      The Teacher's Note url
                    </span>{" "}
                    - you should ensure that all your listeners know that{" "}
                    <a
                      className={classes.Link}
                      href="https://www.myteachersnote.com"
                    >
                      www.myteachersnote.com
                    </a>{" "}
                    is the place to go for help listening on whatever device
                    they may have.
                    <br></br>
                    <br></br>
                    3.{" "}
                    <span className={classes.SpokenWords}>
                      The range of devices
                    </span>{" "}
                    - you will want to make sure people know that they can
                    access Teacher's Note from any Alexa or Google
                    Assistant-enabled device or any smartphone. We want them to
                    know that everyone can find a way to listen, no matter what
                    device they own!
                    <br></br>
                    <br></br>
                    <span style={{ fontStyle: "italic" }}>Nice to have:</span>
                    <br></br>
                    <span className={classes.SpokenWords}>
                      The Teacher's Note Logo
                    </span>{" "}
                    - this will help users know they have come to the right
                    place anytime they see this logo
                    <br></br>
                    <span className={classes.SpokenWords}>
                      Alexa and Google launch phrases
                    </span>{" "}
                    - it's a good idea to tell people how to start the voices
                    experiences - just include that you say, "Open Teacher's
                    Note" on Alexa and "Talk to Teacher's Note" on Google
                    Asssistant. That way, listeners can get started without
                    having to visit our website
                    <br></br>
                    <br></br>
                    <div style={{ textAlign: "center" }}>
                      <span
                        className={classes.SpokenWords}
                        style={{ fontSize: 28 }}
                      >
                        Example:
                      </span>
                    </div>{" "}
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    <span className={classes.SpokenWords}>
                      Hear my classroom updates on Teacher's Note:
                    </span>{" "}
                    I am excited to announce that I am now on Teacher's Note!
                    Teacher's Note allows you to listen to your teacher's notes
                    in their own voice. You can listen to my note for the day on
                    your smartphone by downloading the free Teacher's Note app
                    and looking up this class -{" "}
                    <span className={classes.SpokenWords}>
                      (your class name in bold)
                    </span>
                    , taught by{" "}
                    <span className={classes.SpokenWords}>
                      (your teacher name in bold)
                    </span>{" "}
                    at{" "}
                    <span className={classes.SpokenWords}>
                      (your school name in bold)
                    </span>
                    . Or, you can listen to my note on Alexa or Google Assistant
                    - just tell Alexa to "Open Teacher's Note", or ask Google to
                    "Talk to Teacher's Note." To learn more about how to listen,
                    check out www.myteachersnote.com.
                  </div>
                </div>
              </div>
            </div>
          </div>
          // </div>
        )}
      </Transition>
    </div>
  );
};

export default withAuth(SpreadTheWordPage);
