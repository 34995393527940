import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";

import classes from "./CreatorTutorialPage.module.scss";

const CreatorTutorialPage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  // if (redirectUrl) {
  //   return <Redirect to={redirectUrl}></Redirect>;
  // }

  return (
    <div className={`${classes.FullScreen}`}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            Teacher's Note
          </div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <div
            style={{
              ...questionsDefaultStyle,
              ...questionsTransitionStyles[state],
            }}
            className={classes.QuestionText}
          >
            Get Started
            <div className={classes.SubQuestionText}>
              How to post your notes on Teacher's Note{" "}
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  display: "block",
                  flexDirection: "column",
                  textAlign: "left",
                  backgroundColor: "white",
                  padding: 15,
                  paddingLeft: 25,
                  paddingRight: 25,
                  borderRadius: 15,
                  marginTop: 15,
                }}
              >
                <div className={`${classes.InstructionText}`}>
                  <div>
                    1. Download the Teacher's Note app from the{" "}
                    <a
                      className={classes.Link}
                      href="https://apps.apple.com/us/app/id1526591649"
                    >
                      Apple App Store
                    </a>{" "}
                    or the{" "}
                    <a
                      className={classes.Link}
                      href="https://play.google.com/store/apps/details?id=com.teachersnote"
                    >
                      Google Play Store
                    </a>
                    .
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    2. Sign in with your Teacher's Note account.<br></br>
                    The app gives you a walkthrough after the first time you log
                    in - the following is largely a repetition of the
                    information included there!
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    3. After the walkthrough, you will be prompted to set your
                    school and teacher name. This is how your students will find
                    you on Alexa, Google Assisstant, and the app, so be sure to
                    follow the naming guidelines below.
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    4. Teacher's Note allows you to create classes, so you can
                    post different notes for different groups of students. To
                    create a class, press the red{" "}
                    <span className={classes.SpokenWords}>Create Class</span>{" "}
                    button at the bottom of the screen.
                    <br></br>
                    <br></br>
                    You have the option of setting a password for a class - if
                    you do set one, it will be required each time your listeners
                    access your notes on Alexa and Google Assistant, and
                    whenever someone adds your class in the Teacher's Note app.
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    5. Each class you create has its own note schedule, where
                    you can record notes and determine when they will be
                    available for listening. To access the note schedule for a
                    class, click on the arrow next to the class in your class
                    list.
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    5. To post a note to a day, press the{" "}
                    <span className={classes.SpokenWords}>+</span> button on
                    that day. This will bring up the recording controls,
                    allowing you to record, preview, and save your note.
                    <br></br>
                    <br></br>
                    You also have the option of setting a{" "}
                    <span className={classes.SpokenWords}>
                      Default Note
                    </span>{" "}
                    for each class. This is the note that will be played for any
                    day you have not posted something.
                    <br></br>Default notes are also great for posting notes you
                    want to last across several days!
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    <span
                      className={classes.SpokenWords}
                      style={{ marginLeft: 0 }}
                    >
                      Naming guidelines:
                    </span>{" "}
                    <br></br>
                    <br></br>The names you set for yourself, your school, your
                    classes, and your password are important because they are
                    how your students will access your notes. Alexa and Google
                    Assistant must be able to understand these names when your
                    students say them, so there are a few restrictions on what
                    you can use.
                    <br></br>{" "}
                    <span style={{ fontStyle: "italic" }}>
                      For best results:
                    </span>
                    <br></br> Use real English words
                    <br></br> Common abbreviations such as Mr, Mrs, Dr, AP, and
                    1st are OK, but write them without periods
                    <br></br> Separate words with a space
                    <br></br>
                    <br></br>If you have any questions, don't hesitate to email
                    us!
                  </div>
                </div>
              </div>
            </div>
          </div>
          // </div>
        )}
      </Transition>
    </div>
  );
};

export default withAuth(CreatorTutorialPage);
