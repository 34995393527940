import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { BrowserRouter, HashRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { createBrowserHistory, createHashHistory } from "history";
import dev_config from "./config_dev";
import prod_config from "./config_prod";
const config = dev_config.useProd ? prod_config : dev_config;

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: config.apiGateway.API_NAME,
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

const history = createHashHistory();
const location = history.location;

//console.log("location 1: ", location);
//console.log("history: ", history);

const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
if (path) {
  //console.log("path: ", path);
  history.replace(path);
}

console.log("location: ", location);

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
