import React from "react";

import classes from "./SunsetPage.module.scss";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";

const SunsetPage = (props) => {
  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  return (
    <div className={classes.FullScreenMessage} style={{ ...props.style }}>
      <div className={classes.Title} style={{ ...props.titleStyle }} key={20}>
        Thank you and farewell!
      </div>
      <div className={classes.Subtitle} key={21}>
        The creators behind Teacher's Note no longer have the time and resources
        to maintain the platform. As a result, Teacher's Note is no longer in
        operation. Feel free to explore the website to get an idea of what was
        possible with Teacher's Note. Thank you to all our users and supporters.
        Goodbye (for now)!
      </div>
    </div>
  );
};

export default SunsetPage;
