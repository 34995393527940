import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import LoginButton from "../../components/LoginButton/LoginButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";

import classes from "./GoogleInstructionsPage.module.scss";

const GoogleInstructionsPage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  // if (redirectUrl) {
  //   return <Redirect to={redirectUrl}></Redirect>;
  // }

  return (
    <div className={`${classes.FullScreen}`}>
      <LoginButton></LoginButton>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            Teacher's Note
          </div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <div
            style={{
              ...questionsDefaultStyle,
              ...questionsTransitionStyles[state],
            }}
            className={classes.QuestionText}
          >
            Listen with Google Assistant:
            <div className={classes.SubQuestionText}>
              Google Home, Google Nest Hub, any Assistant-enabled device{" "}
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  display: "block",
                  flexDirection: "column",
                  textAlign: "left",
                  backgroundColor: "white",
                  padding: 15,
                  paddingLeft: 25,
                  paddingRight: 25,
                  borderRadius: 15,
                  marginTop: 15,
                  //maxWidth: "85%",
                }}
              >
                <div className={`${classes.InstructionText}`}>
                  <div>
                    1. To hear what your teacher has posted for the day, say
                    {"  "}
                    <span className={classes.SpokenWords}>
                      "Ok Google, Open Teacher's Note."
                    </span>{" "}
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    2. Google Assistant will then ask you about the name of your
                    school, your teacher, and your class. Once you have provided
                    this information, your Teacher's Note will be played for
                    you! <br></br>
                    <br></br>
                    {"  "}
                    <span className={classes.SpokenWords}>Tips{"  "}</span>{" "}
                    <br></br>
                    Your teacher will have provided you with the name, school
                    name, and class name they are using on Teacher's Note - make
                    sure you say them exactly as they are!
                    <br></br>
                    {/* If your class name has a course number in it, Alexa needs
                    you to say the number in a way she can understand. For
                    example, if your class name is History 211, Alexa needs you
                    to pronounce that as either "
                    <span style={{ fontStyle: "italic" }}>
                      History two hundred eleven
                    </span>
                    " or "
                    <span style={{ fontStyle: "italic" }}>
                      History two one one
                    </span>
                    "<br></br> */}
                    <br></br>
                    For more detailed instructions, please see our{" "}
                    <a
                      className={classes.Link}
                      href="https://assistant.google.com/services/a/uid/0000001a87070eb4"
                    >
                      Google Action page
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          // </div>
        )}
      </Transition>
    </div>
  );
};

export default GoogleInstructionsPage;
