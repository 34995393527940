import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import LoginButton from "../../components/LoginButton/LoginButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SunsetPage from "../SunsetPage/SunsetPage";
import {
  faArrowCircleRight,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import logoBottom from "../../assets/images/shadowed_eagle_bottom.png";
import Image from "react-bootstrap/Image";
//import Loader from "react-loader-spinner";
import classes from "./LandingPage.module.scss";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const LandingPage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const titleTransitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 0 },
  };

  const logoDefaultStyle = {
    transition: `max-height 1s`,
    maxHeight: "calc(50% - 160px)",
    height: "calc(50% - 160px)",
  };

  const logoTransitionStyles = {
    entering: { maxHeight: "calc(50% - 160px)" },
    entered: { maxHeight: "10px" },
  };

  const questionsDefaultStyle = {
    transition: `1000ms maxHeight ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  // if (redirectUrl) {
  //   return <Redirect to={redirectUrl}></Redirect>;
  // }

  return (
    <React.Fragment>
      <div className={`${classes.FullScreen}`}>
        <Transition
          in={inProp}
          timeout={{
            appear: 2000,
            enter: 2000,
            exit: 300,
          }}
          appear
          unmountOnExit
        >
          {(state) => (
            <React.Fragment>
              <LoginButton hide={state === "entering"}></LoginButton>
              <div
                style={{
                  ...logoDefaultStyle,
                  ...logoTransitionStyles[state],
                }}
              ></div>
            </React.Fragment>
          )}
        </Transition>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            //alignItems: "center",
          }}
        >
          <div className={classes.LogoImage}>
            <Image src={logo} fluid />

            <Transition
              in={inProp}
              timeout={{
                appear: 1000,
                enter: 1000,
                exit: 300,
              }}
              appear
              unmountOnExit
            >
              {(state) => (
                <React.Fragment>
                  <div
                    style={{
                      ...titleDefaultStyle,
                      ...titleTransitionStyles[state],
                    }}
                    className={`${classes.LogoText}`}
                  >
                    Teacher's Note
                    {/* <Image
                      src={logoBottom}
                      fluid
                      style={{ marginTop: "15px" }}
                    /> */}
                  </div>
                </React.Fragment>
              )}
            </Transition>
          </div>
        </div>
        <Transition
          in={inProp}
          timeout={{
            appear: 3000,
            enter: 1000,
            exit: 300,
          }}
          appear
          unmountOnExit
        >
          {(state) => (
            <div
              style={{
                ...questionsDefaultStyle,
                ...questionsTransitionStyles[state],
                fontWeight: "300",
              }}
              className={classes.QuestionText}
            >
              {/* Connected by <span style={{ fontWeight: "bold" }}>Voice</span> */}
              <SunsetPage />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className={classes.TitleDivider}></div>
              </div>
              {/* <div
             style={{
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
             }}
           >
             <div
               onClick={() => {
                //console.log("clicked get started");
                 //setRedirectUrl("/auth");
                 //history.push("/google-instructions");
               }}
               className={`${classes.GetStartedButton}`}
               style={{}}
             >
               <div>Get Started</div>
             </div>
           </div> */}
              <div className="row" style={{ flex: "1 1 auto" }}>
                <div
                  className={`col-sm-12 col-lg-6 col-md-6 ${classes.LeftRowItem}`}
                >
                  <div
                    onClick={() => {
                      //console.log("clicked listen");
                      //history.push("/select-device");
                    }}
                    className={`${classes.AnswerText}`}
                  >
                    <div className={classes.GroupTitle}>Educators</div>
                    <div className={classes.AnswerSubtext}>
                      Teachers use the simple{" "}
                      <span className={classes.PurpleInlineText}>
                        Teacher's Note
                      </span>{" "}
                      app to record and post classroom updates, reminders, or
                      encouragement.
                    </div>
                    <div className={classes.AnswerSubicon}>
                      <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        style={{ fontSize: "25", margin: "5px" }}
                      ></FontAwesomeIcon>
                    </div>
                  </div>
                </div>
                {/* <div
               className={`col-sm-12 col-lg-4 col-md-4 ${classes.MiddleSvg}`}
             >
               <div style={{ fontWeight: "bold", width: 100 }}>
                 Connected by Voice
               </div>
               <ArrowSVG className={classes.SvgArrow}></ArrowSVG>
             </div> */}
                <div
                  className={`col-sm-12 col-lg-6 col-md-6 ${classes.RightRowItem}`}
                >
                  <div
                    onClick={() => {
                      //console.log("clicked contribute");
                      //setRedirectUrl("/auth");
                      //history.push("/auth");
                    }}
                    className={`${classes.AnswerText}`}
                    // style={{ width: 390 }}
                  >
                    <div className={classes.GroupTitle}>Students</div>
                    <div className={classes.AnswerSubtext}>
                      Students and parents can listen to the latest update from
                      their teachers - all they need is a smartphone or any
                      Alexa- or Google Assistant-enabled device!
                    </div>
                    <div className={classes.AnswerSubicon}>
                      {/* Learn more about posting on Teacher's Note */}
                      <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        style={{ fontSize: "25", margin: "5px" }}
                      ></FontAwesomeIcon>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "whitesmoke",
                }}
              >
                <div
                  onClick={() => {
                    //console.log("clicked get started");
                    //setRedirectUrl("/auth");
                    history.push("/start");
                  }}
                  className={`${classes.GetStartedButton}`}
                  style={{}}
                >
                  <div>Get Started </div>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                      fontSize: "20",
                      margin: "5px",
                      marginLeft: "15px",
                    }}
                  ></FontAwesomeIcon>
                </div>
              </div>
            </div>
          )}
        </Transition>
      </div>
    </React.Fragment>
  );
};

export default LandingPage;
