import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import LoginButton from "../../components/LoginButton/LoginButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";
import Collapsible from "react-collapsible";

import classes from "./CreatorPreinfoPage.module.scss";
import { FAQS } from "./faqs";

const CreatorPreinfoPage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  // if (redirectUrl) {
  //   return <Redirect to={redirectUrl}></Redirect>;
  // }

  const collapsableList = FAQS.map((faq, index) => {
    return (
      <Collapsible
        trigger={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              maxWidth: 650,
            }}
          >
            <div>{faq.question}</div>
            <FontAwesomeIcon
              icon={faChevronDown}
              style={{ fontSize: "20", marginLeft: 15, marginRight: 10 }}
            ></FontAwesomeIcon>{" "}
          </div>
        }
        triggerWhenOpen={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              maxWidth: 650,
            }}
          >
            <div>{faq.question}</div>
            <FontAwesomeIcon
              icon={faChevronUp}
              style={{ fontSize: "20", marginLeft: 15, marginRight: 10 }}
            ></FontAwesomeIcon>{" "}
          </div>
        }
        key={index}
      >
        <div
          style={{
            alignItems: "flex-start",
            display: "block",
            //display: "flex",
            flexDirection: "column",
            textAlign: "left",
            backgroundColor: "white",
            padding: 15,
            paddingLeft: 25,
            paddingRight: 25,
            borderRadius: 15,
            marginTop: 15,
            //width: "85%",
          }}
        >
          <div className={`${classes.InstructionText}`}>{faq.answer}</div>
        </div>
      </Collapsible>
    );
  });

  return (
    <div className={`${classes.FullScreen}`}>
      <LoginButton></LoginButton>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            Teacher's Note
          </div>
        </div>
      </div>
      <div
        style={{
          ...questionsDefaultStyle,
          ...questionsTransitionStyles["entered"],
        }}
        className={classes.QuestionText}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "whitesmoke",
          }}
        >
          <div
            onClick={() => {
              //console.log("clicked sign up");
              //setRedirectUrl("/auth");
              history.push("/auth?mode=sign-up");
            }}
            className={`${classes.SignUpButton}`}
            style={{}}
          >
            <div>Sign Up </div>
            {/* <FontAwesomeIcon
              icon={faArrowRight}
              style={{ fontSize: "20", margin: "5px", marginLeft: "15px" }}
            ></FontAwesomeIcon> */}
          </div>
        </div>
        {/* Listen with Google Assistant:
        <div className={classes.SubQuestionText}>
          Google Home, Google Nest Hub, any Assistant-enabled device{" "}
        </div> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "whitesmoke",
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              width: "85%",
              //width: 600,
            }}
          >
            {collapsableList}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatorPreinfoPage;
