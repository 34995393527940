export default {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://cte68jztbi.execute-api.us-east-1.amazonaws.com/prod",
    API_NAME: "teachersnote-prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_SOANVt1mL",
    APP_CLIENT_ID: "49ernd7lnnmgv02qi5scfq1nm4",
    IDENTITY_POOL_ID: "us-east-1:a8ec3ba9-2a2e-432a-bb94-984227f59971",
  },
  loginTitle: "Teacher's Note",
  useLogs: false,
  stripePk: "placeholder",
};
