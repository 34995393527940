export default {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://ifcmlx73r2.execute-api.us-east-1.amazonaws.com/dev",
    API_NAME: "teachersnote-dev",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_CxGGkfBZb",
    APP_CLIENT_ID: "4rnsq3mbien4ipanfeg5isqhn6",
    IDENTITY_POOL_ID: "us-east-1:89445ac6-6a25-4f80-bc34-7687a2df6c33",
  },
  loginTitle: "Teacher's Note-dev",
  useProd: true,
  useLogs: true,
  stripePk: "placeholder",
};
