import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import LoginButton from "../../components/LoginButton/LoginButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";

import classes from "./IntroSurveyPage.module.scss";

const IntroSurveyPage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  return (
    <div className={`${classes.FullScreen}`}>
      <LoginButton></LoginButton>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            Teacher's Note
          </div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <div
            style={{
              ...questionsDefaultStyle,
              ...questionsTransitionStyles[state],
            }}
            className={classes.QuestionText}
          >
            I would like to...
            <div className="row" style={{ flex: "1 1 auto" }}>
              <div
                className={`col-sm-12 col-lg-6 col-md-6 ${classes.LeftRowItem}`}
              >
                <div
                  onClick={() => {
                    //console.log("clicked listen");
                    history.push("/select-device");
                  }}
                  className={`${classes.AnswerText}`}
                >
                  <div>Listen</div>
                  <div className={classes.AnswerSubtext}>
                    Learn how to listen to your teacher on Teacher's Note
                  </div>
                  <div className={classes.AnswerSubicon}>
                    {/* Learn more about posting on Teacher's Note */}
                    <FontAwesomeIcon
                      icon={faArrowCircleRight}
                      style={{ fontSize: "25", margin: "5px" }}
                    ></FontAwesomeIcon>
                  </div>
                </div>
              </div>
              <div
                className={`col-sm-12 col-lg-6 col-md-6 ${classes.RightRowItem}`}
              >
                <div
                  onClick={() => {
                    //console.log("clicked contribute");
                    //setRedirectUrl("/auth");
                    history.push("/pre-info");
                  }}
                  className={`${classes.AnswerText}`}
                >
                  <div>Create</div>
                  <div className={classes.AnswerSubtext}>
                    Learn more about posting notes on Teacher's Note
                  </div>
                  <div className={classes.AnswerSubicon}>
                    {/* Learn more about posting on Teacher's Note */}
                    <FontAwesomeIcon
                      icon={faArrowCircleRight}
                      style={{ fontSize: "25", margin: "5px" }}
                    ></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

export default IntroSurveyPage;
