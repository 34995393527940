import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import classes from "./PrivacyPolicy.module.scss";

const PrivacyPolicy = (props) => {
  return (
    <div className={classes.FullScreenMessage} style={{ ...props.style }}>
      <div className={classes.Title} style={{ ...props.titleStyle }} key={20}>
        Privacy Policy
      </div>
      <div className={classes.Subtitle} key={21}>
        <br></br>
        Your privacy is important to us. It is Harbourview Technologies LLC's
        policy to respect your privacy regarding any information we may collect
        from you through our platform, Teacher's Note.<br></br>
        <br></br>
        We only ask for personal information when we truly need it to provide a
        service to you. We collect it by fair and lawful means, with your
        knowledge and consent. We also let you know why we’re collecting it and
        how it will be used.<br></br>
        <br></br>
        We only retain collected information for as long as necessary to provide
        you with your requested service. What data we store, we’ll protect
        within commercially acceptable means to prevent loss and theft, as well
        as unauthorized access, disclosure, copying, use or modification.
        <br></br>
        <br></br>
        We don’t share any personally identifying information publicly or with
        third-parties, except when required to by law.<br></br>
        <br></br>
        Our app may link to external sites that are not operated by us. Please
        be aware that we have no control over the content and practices of these
        sites, and cannot accept responsibility or liability for their
        respective privacy policies.<br></br>
        <br></br>
        You are free to refuse our request for your personal information, with
        the understanding that we may be unable to provide you with some of your
        desired services.<br></br>
        <br></br>
        Your continued use of our app will be regarded as acceptance of our
        practices around privacy and personal information. If you have any
        questions about how we handle user data and personal information, feel
        free to contact us.<br></br>
        <br></br>
        <br></br>
        <br></br>
        This policy is effective as of 6 August 2020.
      </div>
      {/* {props.hideIcon ? null : (
        <div>
          <div className={classes.IconContainer}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "3em" }}
            ></FontAwesomeIcon>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default PrivacyPolicy;
