import React from "react";
import classes from "./CreatorPreinfoPage.module.scss";

const FAQS = [
  {
    question: "How do I post on Teacher's Note?",
    answer: (
      <div>
        Posting on Teacher's Note is easy to do! All you need is the Teacher's
        Note app, available through the{" "}
        <a
          className={classes.Link}
          href="https://apps.apple.com/us/app/id1526591649"
        >
          Apple App Store
        </a>{" "}
        or the{" "}
        <a
          className={classes.Link}
          href="https://play.google.com/store/apps/details?id=com.teachersnote"
        >
          Google Play Store
        </a>
        .. The app makes it simple to record and schedule your notes.
      </div>
    ),
  },
  {
    question: "What can I post on Teacher's Note?",
    answer: (
      <div>
        We designed Teacher's Note to help keep teachers connected with their
        students and parents, allowing them to use the power of a familiar voice
        to educate, inform, or inspire. Within that broad goal, the sky is the
        limit! Your notes could be classroom updates on assignments,
        encouragement for students, updates for parents, or short recaps of
        classroom material, or even extra credit questions! <br></br>One
        important rule: due to technical restrictions, recordings have a time
        limit of 4 minutes.{" "}
      </div>
    ),
  },
  {
    question: "How do people listen to what I have posted on Teacher's Note?",
    answer: (
      <div>
        Anyone with a smartphone can listen to you on the Teacher's Note app by
        searching for you and your class by name. Anyone with an Alexa- or
        Google Assistant-enabled device can listen to your note by asking for
        you and your class by name!
      </div>
    ),
  },
  // {
  //   question: "Who can listen to Teacher's Note?",
  //   answer: (
  //     <div>
  //       We built Teacher's Note to allow as much listener access as possible.
  //       People can listen on their Alexa- and Google-Assistant enabled devices
  //       (think: smart speakers, like the Amazon Echo). They can also listen on
  //       any smartphone. This means that the vast majority of people will be able
  //       to listen if they choose to, as long as they have access to a
  //       smartphone.
  //     </div>
  //   ),
  // },

  {
    question:
      "How do I help my students and their parents find me on Teacher's Note?",
    answer: (
      <div className={classes.WrappedAnywhere}>
        We have created materials to help you tell people about Teacher's Note!
        Our website is designed to help walk new users through the process of
        finding their teacher step-by-step, so parents and students can just
        visit www.myteachersnote.com to get started.
        <br></br>
        Additionally, once you log in to your account, you will have access to a
        sample announcement to let people know how to listen to you and
        directing them to resources with everything they will need to get
        started.
      </div>
    ),
  },
  // {
  //   question: "Will I have to pay anything for Teacher's Note?",
  //   answer: (
  //     <div className={classes.WrappedAnywhere}>
  //       TBD<br></br>
  //       <br></br>Contact us at teachersnote@harbourviewtechnologies.com for
  //       pricing inquiries.
  //     </div>

  //   ),
  // },
  // {
  //   question: "Do I have to post on Teacher's Note every day?",
  //   answer: (
  //     <div>
  //       Definitely not! The way you use Teacher's Note is completely up to you and
  //       what is best for yourself and your listeners. We have designed Teacher's Note
  // to gracefully handle different patterns of use. The app allows
  //       you to schedule posts in advance, schedule the same recording for
  //       multiple days, or just not post anything at all. If you want to post
  //       something new for each day, great! If not, that is fine as well - it
  //       will work equally well with weekly or even less frequent updates.
  //     </div>
  //   ),
  // },
  {
    question: "I have a question you didn't answer. What should I do?",
    answer: (
      <div className={classes.WrappedAnywhere}>
        We would love to hear from you! Feel free to contact us at
        teachersnote@harbourviewtechnologies.com with any questions or feedback.
      </div>
    ),
  },
];
export { FAQS };
