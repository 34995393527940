import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import classes from "./SimpleMarketing.module.scss";

const SimpleMarketing = (props) => {
  return (
    <div className={classes.FullScreenMessage} style={{ ...props.style }}>
      <div className={classes.Title} style={{ ...props.titleStyle }} key={20}>
        Welcome to Teacher's Note
      </div>
      <div className={classes.Subtitle} key={21}>
        Teacher's Note allows you to record short classroom notes and then post
        them for listening on the Teacher's Note Alexa Skill, Google Action, iOS
        app, or Android app. Look for us soon on the Alexa Skill Store, App
        Store, and Google Play.
      </div>
      {/* {props.hideIcon ? null : (
        <div>
          <div className={classes.IconContainer}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "3em" }}
            ></FontAwesomeIcon>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default SimpleMarketing;
